import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import colors from "@//utils/colors";
import SvgIcon from "@mui/material/SvgIcon";
import LogOutIcon from "@/assets/icons/log-out.svg";
import SearchIcon from "@/assets/icons/search-md.svg";
import HomeLineIcon from "@/assets/icons/home-line.svg";
import UserPlusIcon from "@/assets/icons/user-plus.svg";
import Image from "next/image";
import { usePathname, useRouter } from "next/navigation";
import { signOut, useSession } from "next-auth/react";
import { User } from "@/services/get-current-user/getCurrentUserService";
import UIText from "../text/UIText";
import { useCurrentSessionContext } from "@/context/currentSessionContext";
import SearchBox from "../SearchBox";
import { useState } from "react";

import styles from "./UISideBar.module.css";
import UIWhatsApp from "../whatsapp-box/UIWhatsApp";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export interface UISideBarProps {
  open: boolean;
  version: string;
}



const phoneText = "55-3880-1395";
const guideURL = process.env.GUIDE || 'https://portalint.aserta.com.mx/es/cursos-asi';

const handleWhatsAppClick = () => {    
  const atomPlugin = document.querySelector('atom-plugin-web'); // Seleccionar el componente web
    if (atomPlugin && atomPlugin.shadowRoot) {
      const messengerButton = atomPlugin.shadowRoot.getElementById('messenger-button');
      if (messengerButton) {
        messengerButton.click(); // Hacer clic automáticamente
      }
    }
  // const phone = "5215538801395";
  // const textInitial = "Ayuda así";
  // const encodedText = encodeURIComponent(textInitial);
  // const whatsappUrl = process.env.WHATSAPP_URL || `https://api.whatsapp.com/send/?phone=${phone}&text=${encodedText}`;
  // window.open(whatsappUrl, '_blank');
};

const UISideBar: React.FC<UISideBarProps> = (
  { open, version },
) => {
  const { data, status } = useSession();
  const { setSession, session } = useCurrentSessionContext();

  const [searchBoxValue, setSearchBoxValue] = useState("");

  const { push } = useRouter();
  const handleOnClickSearch = () => {
    if (searchBoxValue && searchBoxValue.trim()) {
      push(`/?search=${searchBoxValue}`);
      setSearchBoxValue("");
    }
  };

  const user = data?.user as User;
  const options = [
    {
      key: "Busqueda",
      children: (
        open ? <SearchBox
          classes={{
            paper: styles.SearchBoxRoot,
            searchIcon: styles.SearchBoxSearchIcon,
            inputBase: styles.SearchBoxInputBase,
          }}
          label="Buscar"
          placeholder="Buscar póliza, factura, cliente"
          value={searchBoxValue}
          onChange={setSearchBoxValue}
          onClickSearch={handleOnClickSearch}
          withoutButton
        /> : <IconButton sx={{ p: "5px" }} aria-label="menu">
          <SearchIcon />
        </IconButton>
      ),
    },
    { text: "Mis solicitudes", icon: HomeLineIcon, key: "Home", route: "/" },
    {
      text: "Alta",
      icon: UserPlusIcon,
      key: "Nueva alta",
      route: "/registration",
    },
  ];

  const router = useRouter();

  const text = {
    color: colors.gray100,
  };

  const handleGuideClick = () => {
    window.open(guideURL, '_blank');
  };

  const pathname = usePathname();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {version && (
        <Stack direction="row" spacing={1} m={2}>
          <Typography variant="subtitle2" sx={text}>
            Versión: {version}
          </Typography>
        </Stack>
      )}

      <Stack direction="row" spacing={2} mt={2} mb={2} ml={1}>
        <Image
          src="/assets/logos/aserta_smile.svg"
          alt="logo"
          width={40}
          height={40}
        />
        <Typography variant="h6" sx={{ color: `${colors.gray100}` }}>
          Aserta
        </Typography>
      </Stack>

      {pathname != "/contract" && (
        <List
          sx={{
            height: "calc(100vh - 180px)",
            alignItems: "flex-start",
            padding: 0,
            margin: 0,
            display: "block",
          }}
          disablePadding
        >
          {options.map((option) => (
            <div className={styles.SearchBox} key={option.key}>
              {option.text && (
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ justifyContent: "initial" }}
                    onClick={() => {
                      option.route && router.push(option.route);
                    }}
                  >
                    <ListItemIcon sx={{ mr: 0 }}>
                      <SvgIcon sx={{ mr: 0 }}>
                        <option.icon />
                      </SvgIcon>
                    </ListItemIcon>
                    <UIText text={option.text} color="white" />
                  </ListItemButton>
                </ListItem>
              )}
              {option.children && (
                <div className={styles.SearchBox}>{option.children}</div>
              )}
            </div>
          ))}
        </List>
      )}
      <UIWhatsApp open={open}
        onWhatsAppClick={handleWhatsAppClick}
        bgColor="#FFF"
        hoverBgColor="#FFF"
        icon={<Image src="/assets/icons/asi.svg" alt={"Icono soporte"} width="38" height="38" />}
        text={
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center'
          }}>
            <UIText
              text="Chat de soporte"
              color="white"
              size="text-s"
              weight="semibold"
            />
            
          </Box>
        }
        textHint=""
        key={"soportefabicon"}
        sx={{ mb: 3, textdecoration: 'none' }}
         />  

      <UIWhatsApp open={open}
        onWhatsAppClick={handleGuideClick}
        bgColor="#F2F4F7"
        hoverBgColor="#E4E7EC"
        icon={<InfoOutlinedIcon sx={{ color: '#566680' }} />}
        text={
          <UIText
            text="Tutoriales"
            color="white"
            size="text-s"
            weight="semibold"
          />
        }
        textHint="Tutoriales"
        key={"guidefabicon"}
        sx={{ mb: 3, textdecoration: 'none' }} />
          
      {pathname != "/contract" && (
        <Paper
          sx={{
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            backgroundColor: `${colors.blue1}`,
          }}
          elevation={0}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: "left" }}
            marginLeft={2}
            marginTop={2}
            marginBottom={2.35}
          >
            <Avatar sx={{ height: "35px", width: "35px", marginLeft: "5px" }} />
            <Stack width={"130px"}>
              <Typography noWrap sx={{ color: `${colors.gray100}`}}>
                {session?.user?.raw?.[0].nombreUsuario}
              </Typography>
              <Typography sx={{ color: `${colors.gray100}` }}>
                {user?.id}
              </Typography>
            </Stack>
            <SvgIcon
              onClick={() => {
                setSession(undefined);
                signOut();
              }}
            >
              <LogOutIcon />
            </SvgIcon>
          </Stack>
        </Paper>
      )}
    </div>
  );
};

export { handleWhatsAppClick }
export default UISideBar;
