import { FunctionComponent } from "react";
import {
  LinearProgress,
  CircularProgress,
  Box,
} from "@mui/material";
import UIProgressProps from "./UIProgressProps";
import UIText from "../text/UIText";

import styles  from "./styles.module.css";

const UIProgress: FunctionComponent<UIProgressProps> = ({
  type,
  textColor,
  percentage,
}) => {
  return (
    <Box
      sx={{ width: "100%" }}
      display={"flex"}
      flexDirection={"row"}
      gap={"10px"}
      alignItems={"center"}
    >
      {type === "linear" ? (
        <LinearProgress
          sx={{ width: "100%" }}
          classes={{
            root: styles.root,
            bar1Determinate: styles.bar1Determinate,
            bar1Indeterminate: styles.test
          }}
          variant="determinate"
          value={percentage}
        />
      ) : (
        <CircularProgress value={percentage} />
      )}
      <Box sx={{minWidth: "47px", display: "flex", justifyContent: "right"}} >
      <UIText
        text={`${percentage}%`}
        size={"text-s"}
        color={textColor || "white"}
      />
      </Box>
    </Box>
  );
};

export default UIProgress;
