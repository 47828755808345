import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import UIButton from "../buttons/UIButton";

import styles from "./SearchBox.module.css";

interface ISearchBox {
  label?: string;
  placeholder?: string;
  value: string;
  onChange: (search: string) => void;
  onClickSearch: () => void;
  withoutButton?: boolean;
  classes?: {
    root?: string;
    paper?: string;
    iconButton?: string;
    searchIcon?: string;
    inputBase?: string;
    button?: string;
  };
}

const SearchBox = ({
  label,
  placeholder,
  value,
  onChange,
  onClickSearch,
  withoutButton,
  classes,
}: ISearchBox) => {
  const handleOnChanghe = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (onChange) onChange(val);
  };
  const handleOnSearch = () => {
    if (onClickSearch) onClickSearch();
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (onClickSearch) onClickSearch();
    }
  };

  return (
    <div key={label} className={`${styles.root} ${classes?.root}`}>
      <Paper classes={{ root: `${styles.paper} ${classes?.paper}` }}>
        <IconButton
          className={`${classes?.iconButton}`}
          sx={{ p: "10px" }}
          aria-label="menu"
        >
          <SearchIcon className={`${classes?.searchIcon}`} />
        </IconButton>
        <InputBase
          className={`${classes?.inputBase}`}
          placeholder={placeholder}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={handleOnChanghe}
        />
      </Paper>
      {!withoutButton && (
        <UIButton
          color="secondary"
          label="Buscar"
          onClick={handleOnSearch}
          //disabled={!value}
        />
      )}
    </div>
  );
};

export default SearchBox;
