import React, { useCallback } from 'react';
import { Box, Fab } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import UIText from '../text/UIText';
import { UIWhatsAppProps } from './UIWhatsAppProps';

const UIWhatsApp: React.FC<UIWhatsAppProps> = React.memo(({ open, onWhatsAppClick,   icon = <WhatsAppIcon />,
  text = "¿Necesitas ayuda?",
  textHint = "Escríbenos por WhatsApp",
  bgColor = '#25D366',
  hoverBgColor = '#128C7E',
  sx }) => {
  const handleClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onWhatsAppClick();
  }, [onWhatsAppClick]);

  const fabSx = {
    bgcolor: bgColor,
    '&:hover': {
      bgcolor: hoverBgColor,
    },
    width: 38,
    height: 38,
    mr: 2,
  };
  
  const boxSx = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    ml: 2,
    mb: 1,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      textDecoration: 'none',
    },
    textDecoration: 'none',
    ...sx,
  };

  return (
    <Box 
      component="a"
      href="#"
      onClick={handleClick}
      sx={boxSx}
    >
      <Fab
        color="primary"
        aria-label={textHint}
        sx={fabSx}
      >
        {icon}
      </Fab>
      {open && (
        text
      )}
    </Box>
  );
});

UIWhatsApp.displayName = 'UIWhatsApp';

export default UIWhatsApp;