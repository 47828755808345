import { FileToBeValidatedResponse } from "@/services/get-files-to-be-validated/getFilesToBeValidatedService";
import { GetInfoFileResponse } from "@/services/get-info-file/getInfoFileService";
import React, { createContext, useContext, useEffect, useState } from "react";

export interface Docs {
  csf: GetInfoFileResponse;
  IdentityDocument: GetInfoFileResponse;
  constitutiveAct: GetInfoFileResponse;
  legaPower: GetInfoFileResponse;
}
export interface ItemPerson {
  files: FileToBeValidatedResponse[];
  filesRL: FileToBeValidatedResponse[][];
  moralPerson: boolean;
  validated: boolean;
}

export interface Current {
  usuario?: ItemPerson;
  tipo?: string;
  persona?: "Física" | "Moral";
  obligadoId?: number;
  extranjero?: boolean;
}

export interface DataFiado {
  rfc: string;
  fullName?: string;
  razonsocial?: string;
  owner: string;
}

export interface contractualData {
  cotejoManualAserta: string;
  idAgenteAserta: string;
  idPuestoAgenteAserta: string;
  cotejoManualInsurgentes: string;
  idAgenteInsurgentes: string;
  idPuestoAgenteInsurgentes: string;
}
export interface InfoFiado {
  fiado?: ItemPerson;
  obligado?: ItemPerson[];
  usuarioActual?: Current;
  company?: string;
  update: boolean;
  edit?: boolean;
  ownerToUpload?: string;
  compareFiado?: DataFiado[];
  contractualData?: contractualData;
}
interface InfoFiadoContextType {
  data: InfoFiado | undefined;
  setData: React.Dispatch<React.SetStateAction<InfoFiado | undefined>>;
  idProcessFiado: string;
  setIdProcessFiado: React.Dispatch<React.SetStateAction<string>>;
}
const InfoFiadoContext = createContext<InfoFiadoContextType | undefined>(undefined);

interface PropsInfoFiadoProvider {
  children: React.ReactNode;
}
const InfoFiadoProvider: React.FC<PropsInfoFiadoProvider> = ({ children }) => {
  const [data, setData] = useState<InfoFiado | undefined>(undefined);
  const [idProcessFiado, setIdProcessFiado] = useState("");

  useEffect(() => {
    if (data === undefined) return;
    localStorage.setItem(idProcessFiado, JSON.stringify(data));
  }, [data, idProcessFiado]);

  useEffect(() => {
    const storedData = localStorage.getItem(idProcessFiado);
    if (storedData && storedData !== "undefined") {
      setData(JSON.parse(storedData));
    }
  }, [idProcessFiado]);
  return <InfoFiadoContext.Provider value={{ idProcessFiado, setIdProcessFiado, data, setData }}>{children}</InfoFiadoContext.Provider>;
};

const useInfoFiadoContext = () => {
  const context = useContext(InfoFiadoContext);
  if (context === undefined) {
    throw new Error("useInfoFiadoContext debe ser usado dentro de un InfoFiadoProvider");
  }
  return context;
};

export { InfoFiadoProvider, InfoFiadoContext, useInfoFiadoContext };
