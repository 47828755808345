
import UIDrawer from "@/components/drawer/UIDrawer";
import UISideBar from "@/components/sidebar/UISideBar";
import colors from "@/utils/colors";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import UILoader from "@/components/loader/UILoader";
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import UIText from "@/components/text/UIText";

interface UIRightSidebarLayoutProps {
  children: React.ReactNode;
  drawerWidth: number;
  open: boolean;
  messageLoading?: string;
  titleLoading?: string;
  isLoading?: boolean;
  marginRight?: number;
  percentage?: number;
  isSlow?: boolean;
}
const UIRightSidebarLayout: React.FC<UIRightSidebarLayoutProps> = ({
  children, drawerWidth = 240, open, isLoading = false, messageLoading, titleLoading, marginRight, percentage, isSlow
}) => {
  const memoizedChildren = useMemo(() => children, [children]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1366));
  const [isHovered, setIsHovered] = useState(false);
  const [version, setVersion] = useState<string>("");

  useEffect(() => {
    fetch('/api/version')
      .then((res) => res.json())
      .then((data) => setVersion(data.version));
  }, []);

  const getDrawerOpen = () => {
    if (isSmallScreen && !isHovered) {
      return isHovered;
    }
    return open || isHovered;
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>
      <Box sx={{ display: 'flex', marginRight: marginRight + 'px', backgroundColor: colors.blue4 }}>
        <CssBaseline />
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <UIDrawer open={getDrawerOpen()}
            drawerWidth={drawerWidth}>
            <Box sx={{ backgroundColor: `${colors.blue1}`, height: "100vh" }}>
              <UISideBar open={getDrawerOpen()} version={version} />
            </Box>
          </UIDrawer>
        </div>
        {memoizedChildren}
      </Box>

      {isLoading && <UILoader open={isLoading} loaderText={messageLoading} loaderTextTitle={titleLoading} percentage={percentage} isSlow={isSlow} />}

    </>
  );
};

export default memo(UIRightSidebarLayout);
