import colors from '@/utils/colors';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';




const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface _DrawerProps extends DrawerProps {
  drawerWidth: number;
}
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })<_DrawerProps>(
    ({ theme, open, drawerWidth }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      backgroundColor: `${colors.blue1}`,
      ...(open && {
        ...openedMixin(theme, drawerWidth),
        '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
      }),
      ...(!open && {
        ...closedMixin(theme, drawerWidth),
        '& .MuiDrawer-paper': closedMixin(theme, drawerWidth),
      }),
    }),
  );

interface UIDrawerProps {
  open: boolean;
  onClose?: () => void;
  drawerWidth: number;
  children?: React.ReactNode;
}
const UIDrawer: React.FC<UIDrawerProps> = ({ open, onClose, drawerWidth, children }) => {
  return (
    <Drawer variant="permanent" open={open} drawerWidth={drawerWidth} onClose={onClose}>
      {children}
    </Drawer>
  )
}

export default UIDrawer;
